<div class="footer__bottom">
    <div class="container footer__container-bottom">
        <div class="header__left">
            <a href="https://bnpparibascardif-preprod.go-vip.net/italy/" class="header__logo-link">
                <div class="header__title visuallyhidden">BNP Paribas</div>
                <img class="header__logo"
                alt=""
                    src="/assets/cardif/images/logo-bnp.svg"
                    width="165" height="34">
            </a>
            <div class="footer__baseline-wrapper">
                <span class="header__country">Italia</span>
                <span class="header__baseline">L'assicurazione per un mondo che cambia</span>
            </div>
        </div>
        <nav class="header__right" role="navigation" aria-label="Footer sub navigation">
        </nav>
    </div>
</div>