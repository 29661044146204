import { Component } from '@angular/core';

@Component({
  selector: 'app-setinformativi',
  templateUrl: './setinformativi.component.html'
})
export class SetinformativiComponent {
  titlebanner = "SET INFORMATIVI";
  descriptionbanner = "La documentazione dei nostri prodotti";
  backgroundImageClass = "header-banner-bg-custom";
  visibleFilterColumns:boolean = false;
  hero__title:string = "SET INFORMATIVI";
  hero__baseline:string="I set informativi delle tue polizze assicurative";
  toggleVisibleFilterColumns(){
    this.visibleFilterColumns = !this.visibleFilterColumns;
  }
}
