import { Component, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ChartConfiguration } from 'chart.js';
import { InfoNav } from '../valorizzazioni/enum/info.enum';
import { ValorizzazioniService } from 'src/app/services/valorizzazioni.service';
import { BaseChartDirective } from 'ng2-charts';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-valorizzazioni',
  templateUrl: './valorizzazioni.component.html'
})
export class ValorizzazioniComponent {
  @Input() public otherFilter: any = null; //Gestione filtri che arrivano dall'esterno

  baseUrlSetinformativi = environment.baseUrl.setinformativi + 'set-informativi';
  hero__title:string = "QUOTAZIONI";
  hero__baseline:string="Le quotazioni degli strumenti finanziari collegati alle tue polizze";

  constructor(
    private valorizzazioniService: ValorizzazioniService
  ) { }

  showChartSection: boolean = false;
  searchInputCurrent!: any;
  infoNavEnum = InfoNav;
  blockInfo: any;
  currentIsin!: string;
  @ViewChild(BaseChartDirective) private baseChart: any;

  clearFilterFn(currentFilter: string) {
    this.showChartSection = false;
    const allFiltersObjs = [{filter:'fondiSelection', fn: this.fondiSelection},{filter:'indexSelection', fn: this.indexSelection} ,{filter:'isinSelection', fn: this.isinSelection} ];
    const clearedArray = allFiltersObjs.filter(function (item) {
      return item.filter !== currentFilter
    })
    clearedArray.forEach((filter) => {console.log(filter);
      if(typeof filter != typeof undefined){
        if(typeof filter.fn != typeof undefined){
          filter.fn.clear();
          filter.fn.close();
        }
      }
    });

  }

  //Autocomplete Fondi
  @ViewChild('fondiSelection') fondiSelection: any = null;
  fondi_Keyword = "name";
  fondiSelection_Suggestion: any = [];
  fondiSelection_Selected: any = [];
  fondiSelection_isKeywordLoadingResult: boolean = false;
  fondiSelection_errorMsg: string = "Nessun risultato trovato...";
  loadFondi(event: string) {
    //Caricamento Unit
    this.fondiSelection_isKeywordLoadingResult = true;
    let request = this.getFilter_fondiSelection(event);
    this.valorizzazioniService.searchUnit(request).subscribe((response: any) => {
      this.fondiSelection_Suggestion = [];
      if (response != null && typeof response != typeof undefined) {
        if (response.content != null && typeof response.content != typeof undefined) {
          if (response.content.length > 0) {
            this.fondiSelection_Suggestion = response.content;
          }
        }
      }
      this.fondiSelection_isKeywordLoadingResult = false;
    });
  }
  getFilter_fondiSelection(event: any) {
    let offsetSize = this.fondiSelection_Selected.length;
    let request: any = {
      page: 0,
      size: (100 + offsetSize)
    };
    if (event != "") {
      request["query"] = event;
    }
    return request
  }
  getList_fondiSelection(event: any) {
    console.log(event);
    this.loadFondi(event);
  }
  searchCleared__fondiSelection() {
    this.fondiSelection_Selected = [];
    this.showChartSection = false;
  }
  selectEvent__fondiSelection(item: any) {
    this.fondiSelection_Selected.push(item);
    this.searchInputCurrent = null;
    console.log("FONDI", this.fondiSelection_Selected[0].isin);
    this.currentIsin = this.fondiSelection_Selected[0].isin;
    this.loadChart(this.currentIsin, this.selectedFilter.value);

  }
  onFocused__fondiSelection(e: any) {
    console.log("blockfilter", this.selectedFilter.value);
    this.clearFilterFn('fondiSelection');
    this.getList_fondiSelection("");
    this.filterDateForm.reset();
  }

  //Autocomplete Index
  @ViewChild('indexSelection') indexSelection: any = null;
  index_Keyword = "name";
  indexSelection_Suggestion: any = [];
  indexSelection_Selected: any = [];
  indexSelection_isKeywordLoadingResult: boolean = false;
  indexSelection_errorMsg: string = "Nessun risultato trovato...";
  loadIndex(event: string) {
    //Caricamento Index
    this.indexSelection_isKeywordLoadingResult = true;
    let request = this.getFilter_indexSelection(event);
    this.valorizzazioniService.searchIndex(request).subscribe((response: any) => {
      this.indexSelection_Suggestion = [];
      if (response != null && typeof response != typeof undefined) {
        if (response.content != null && typeof response.content != typeof undefined) {
          if (response.content.length > 0) {
            this.indexSelection_Suggestion = response.content;
          }
        }
      }
      this.indexSelection_isKeywordLoadingResult = false;
    });
  }
  getFilter_indexSelection(event: any) {
    let offsetSize = this.indexSelection_Selected.length;
    let request: any = {
      page: 0,
      size: (100 + offsetSize)
    };
    if (event != "") {
      request["query"] = event;
    }
    return request
  }
  getList_indexSelection(event: any) {
    console.log(event);
    this.loadIndex(event);
  }
  searchCleared__indexSelection() {
    this.indexSelection_Selected = [];
    this.showChartSection = false;

  }
  selectEvent__indexSelection(item: any) {
    this.indexSelection_Selected.push(item);
    this.searchInputCurrent = null;
    this.currentIsin = this.indexSelection_Selected[0].isin;
    this.loadChart(this.currentIsin, this.selectedFilter.value);
  }
  onFocused__indexSelection(e: any) {
    this.clearFilterFn('indexSelection');
    this.getList_indexSelection("");
    this.filterDateForm.reset();
  }


  //Autocomplete Isin
  @ViewChild('isinSelection') isinSelection: any = null;
  isin_Keyword = "temporaryKeyword";
  isinSelection_Suggestion: any = [];
  isinSelection_Selected: any = [];
  isinSelection_isKeywordLoadingResult: boolean = false;
  isinSelection_errorMsg: string = "Nessun risultato trovato...";
  loadIsin(event: string) {
    //Caricamento Isin
    this.isinSelection_isKeywordLoadingResult = true;
    let request = this.getFilter_isinSelection(event);
    this.valorizzazioniService.searchIsin(request).subscribe((response: any) => {
      this.isinSelection_Suggestion = [];
      if (response != null && typeof response != typeof undefined) {
        if (response.content != null && typeof response.content != typeof undefined) {
          if (response.content.length > 0) {
            this.isinSelection_Suggestion = response.content;
            this.isinSelection_Suggestion.map((obj: any) => obj.temporaryKeyword = `${obj.isin} - ${obj.name}`);
          }
        }
      }
      this.isinSelection_isKeywordLoadingResult = false;
    });
  }
  getFilter_isinSelection(event: any) {
    let offsetSize = this.isinSelection_Selected.length;
    let request: any = {
      page: 0,
      size: (100 + offsetSize)
    };
    if (event != "") {
      request["query"] = event;
    }
    return request
  }
  getList_isinSelection(event: any) {
    this.loadIsin(event);
  }
  searchCleared__isinSelection() {
    this.isinSelection_Selected = [];
    this.showChartSection = false;
  }
  selectEvent__isinSelection(item: any) {
    this.isinSelection_Selected.push(item);
    this.searchInputCurrent = null;
    this.currentIsin = this.isinSelection_Selected[0].isin;
    this.loadChart(this.currentIsin, this.selectedFilter.value);

  }
  onFocused__isinSelection(e: any) {
    this.clearFilterFn('isinSelection');
    this.getList_isinSelection("");
    this.filterDateForm.reset();
  }

  handleChange(value: any) {
    if (this.currentIsin) {
      this.loadChart(this.currentIsin, value);
    }
  }

  /**
   * CHART SECTION
   * 
   */

  loadChart(isin: any, interval: any) {
    this.showChartSection = true;
    this.valorizzazioniService.getByIsin(isin, interval).subscribe((response) => {
      this.blockInfo = {
        nome: response.fundName || "-",
        data_valore: response.lastValueDate || "-",
        nav: response.lastValue || "-",
        divisa: response.currency || "-",
        isin: response.isin || "-"
      }
      this.lineChartData.datasets[0].data = [];
      this.lineChartData.labels = [];
      this.lineChartData.datasets[0].data = response.data;
      this.baseChart?.update();
    })
  }

  filterDateForm = new FormGroup({
    filterNav: new FormControl('SIX_MONTHS', { nonNullable: true })
  });

  get selectedFilter(): any {
    return this.filterDateForm.get('filterNav');
  }

  blockFilter: any = [
    {
      '3 MESI': "THREE_MONTHS"
    },
    {
      '6 MESI': "SIX_MONTHS"
    },
    {
      '1 ANNO': "ONE_YEAR"
    },
    {
      '3 ANNI': "THREE_YEARS"
    },
    {
      '5 ANNI': "FIVE_YEARS"
    },
    {
      '10 ANNI': "TEN_YEARS"
    },
    {
      'MAX': "ALL"
    }
  ]

  public lineChartLegend = false;

  public lineChartData: ChartConfiguration<'line', { key: string, value: number }[]>['data'] = {

    datasets: [
      {
        data: [],
        parsing: {
          xAxisKey: 'key',
          yAxisKey: 'value'
        },
        fill: false,
         borderColor: '#00965e',
        backgroundColor: '#00965e',
        pointHoverRadius: 4,
        pointBackgroundColor: '#00965e',
        pointBorderColor: '#00965e',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#00965e',
      }
    ]
  };

  public lineChartOptions: ChartConfiguration<'line'>['options'] = {
    responsive: true,
    elements: {
      line: {
        tension: 0
      },
      point: {
        radius: 0.2
      },
    },
    maintainAspectRatio: false,
  };

}
