<ul class="menu social__menu">
    <li class="menu-item">
        <a href="https://www.linkedin.com/company/bnp-paribas-cardif/posts/?feedView=all" title=" - Nuova finestra"
            target="_blank" rel="noopener noreferrer nofollow">
            <svg class="icon icon-linkedin social-icon" aria-hidden="true" focusable="false">
                <use
                    href="/assets/cardif/iccrea/wp-content/themes/bnpp-cardif-v2c/dist/icons/social.svg#icon-linkedin">
                </use>
            </svg> <span class="social__label visuallyhidden"></span>
        </a>
    </li>
    <li class="menu-item">
        <a href="https://www.facebook.com/bnpparibascardif" title=" - Nuova finestra" target="_blank"
            rel="noopener noreferrer nofollow">
            <svg class="icon icon-facebook social-icon" aria-hidden="true" focusable="false">
                <use
                    href="/assets/cardif/iccrea/wp-content/themes/bnpp-cardif-v2c/dist/icons/social.svg#icon-facebook">
                </use>
            </svg> <span class="social__label visuallyhidden"></span>
        </a>
    </li>
    <li class="menu-item">
        <a href="https://twitter.com/bnppcardifit?ref_src=twsrc%5Etfw" title=" - Nuova finestra" target="_blank"
            rel="noopener noreferrer nofollow">
            <svg class="icon icon-twitter social-icon" aria-hidden="true" focusable="false">
                <use
                    href="/assets/cardif/iccrea/wp-content/themes/bnpp-cardif-v2c/dist/icons/social.svg#icon-twitter">
                </use>
            </svg> <span class="social__label visuallyhidden"></span>
        </a>
    </li>
</ul>